import { useState } from 'react';
import dynamic from 'next/dynamic';

import { CloseIcon } from '@/icons';

import Button from '../button';

import type { FunctionComponent } from 'react';
import type { TypeCartShippingHeaderProps } from './types';

const Modal = dynamic(() => import('../modal-v2').then((mod) => mod.default), {
  ssr: false,
});

const RequestInvoiceForm = dynamic(
  () => import('@/organisms/forms').then((mod) => mod.RequestInvoiceForm),
  { ssr: false },
);

const DownloadInvoice = ({
  orderId,
}: {
  orderId: TypeCartShippingHeaderProps['orderId'];
}) => (
  <Button as="a" href={`/api/invoice/${orderId}`}>
    Descargar albarán
  </Button>
);

const RequestInvoice = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button onClick={() => setShowModal(true)}>Solicitar Factura</Button>

      <Modal open={showModal} setOpen={setShowModal} maxWidth="max-w-2xl">
        <div className="relative bg-primary-50 p-6">
          <RequestInvoiceForm />

          <div
            className="absolute right-2 top-2 md:right-4 md:top-4"
            onClick={() => setShowModal(false)}
          >
            <CloseIcon className="cursor-pointer" />
          </div>
        </div>
      </Modal>
    </>
  );
};

/**
 * CartShippingHeader
 */
export const CartShippingHeader: FunctionComponent<
  TypeCartShippingHeaderProps
> = ({
  className = '',
  title,
  description,
  orderId,
  requestInvoice = false,
}: TypeCartShippingHeaderProps) => (
  <div
    className={`u-body flex items-center justify-between gap-6 bg-primary-100 px-3 py-2 text-primary-900 outline outline-1 outline-primary-100 md:p-4 ${className}`}
  >
    <div>
      <p className="u-body--s font-bold">{title}</p>

      {description && <p className="u-body--xs mt-1">{description}</p>}
    </div>

    <div className="flex flex-wrap justify-end gap-2">
      {requestInvoice && <RequestInvoice />}
      {orderId && <DownloadInvoice orderId={orderId} />}
    </div>
  </div>
);

CartShippingHeader.displayName = 'CartShippingHeader';
