const hasValue = (value: string) => value && value !== '';
const srcWithFallback = (src: string, fallbackIMG: string) =>
  hasValue(src) ? src : fallbackIMG;
const altWithFallback = (alt: string) => (hasValue(alt) ? alt : 'Imágen');

export const imageWithFallback = (
  src: string,
  alt: string,
  fallbackIMG: string,
) => ({
  src: srcWithFallback(src, fallbackIMG),
  alt: altWithFallback(alt),
});
