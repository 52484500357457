import type { ReactElement, ReactNode } from 'react';

export const repeat = (
  n: number,
  element: ReactElement | (({ index }: { index: number }) => ReactElement),
): ReactNode[] =>
  [...Array(n)].map((_, i) =>
    typeof element === 'function' ? (
      element({ index: i })
    ) : (
      <element.type {...element.props} key={i} />
    ),
  );
