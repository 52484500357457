import Skeleton from 'react-loading-skeleton';
import Image from 'next/image';
import { Disclosure } from '@headlessui/react';
import { oneLine } from 'common-tags';

import { ConditionalWrapper } from '@/hoc';
import { ChevronDown, Trash } from '@/icons';
import { CustomSkeletonTheme, NextLink, Number, Price, Text } from '@/atoms';
import { IconButton } from '@/molecules';

import { priceToNumber, toCurrency } from '@/lib/utils';

import type { FunctionComponent } from 'react';
import type { TypeCartLineProps } from './types';

/**
 * CartLine
 */
export const CartLine: FunctionComponent<TypeCartLineProps> = ({
  className = '',
  link,
  size = 'normal',
  image,
  name,
  description,
  quantity = 1,
  price,
  regularPrice = '',
  disabled = false,
  onChange,
  onAdd,
  onSubtract,
  onRemove,
  loading,
  editable = true,
  bundleItems,
  addDisabled = false,
  subtractDisabled = false,
}: TypeCartLineProps) => (
  <CustomSkeletonTheme>
    <div
      className={oneLine`flex w-full items-center p-4 outline outline-1 outline-primary-100 md:py-3 ${size !== 'normal' ? 'md:px-8' : 'md:px-6'} ${Boolean(bundleItems) ? 'flex-col gap-1.5' : ''} ${className} `}
      data-playwright="cartLine"
    >
      <ConditionalWrapper
        condition={Boolean(bundleItems)}
        wrapper={(children) => (
          <div className="flex w-full items-center">{children}</div>
        )}
      >
        {loading ? (
          <Skeleton className="relative block h-[60px] w-12 flex-shrink-0 leading-none xs:h-24 xs:w-20" />
        ) : (
          link &&
          image && (
            <NextLink href={link}>
              <a className="relative block h-[60px] w-12 flex-shrink-0 leading-none xs:h-24 xs:w-20">
                <Image
                  className="w-full"
                  layout="fill"
                  src={image.src}
                  alt={image.alt ?? ''}
                  draggable="false"
                  objectPosition="center"
                  objectFit="contain"
                />
              </a>
            </NextLink>
          )
        )}

        <div className="flex flex-grow flex-col justify-between gap-y-4 pl-4 md:py-3 md:pl-6">
          <div className="flex flex-row items-start justify-between gap-3">
            <div className="w-full">
              {loading ? (
                <Skeleton
                  className="u-font-card line-clamp-3 normal-case"
                  containerClassName="block w-full"
                />
              ) : (
                link && (
                  <NextLink href={link}>
                    <a
                      className="u-font-card line-clamp-3 normal-case"
                      data-playwright="cartLine-name"
                    >
                      {name}
                    </a>
                  </NextLink>
                )
              )}

              {description && !loading && (
                <p className="u-font-card u-font-card--subtitle text-typo-secondary">
                  {description}
                </p>
              )}

              {quantity && price && quantity > 1 && !loading ? (
                <p className="u-font-card u-font-card--subtitle text-typo-secondary">
                  Unidad: {toCurrency(priceToNumber(price) / quantity)}
                </p>
              ) : null}
            </div>

            {onRemove && !loading && editable && (
              <IconButton
                size="small"
                style="outline"
                disabled={disabled || loading}
                onClick={onRemove}
                data-playwright="btn_remove-cartLine"
              >
                <Trash />
              </IconButton>
            )}
          </div>

          <div className="flex flex-row items-end justify-between gap-x-5">
            {loading ? (
              <Skeleton className="h-8" containerClassName="w-20 block" />
            ) : editable && (onChange || onAdd || onSubtract) ? (
              <Number
                value={quantity}
                disabled={disabled || loading}
                onAdd={() => {
                  onAdd && onAdd();
                  onChange && onChange(quantity + 1);
                }}
                onSubtract={() => {
                  onSubtract && onSubtract();
                  onChange && onChange(quantity - 1);
                }}
                addDisabled={addDisabled}
                subtractDisabled={subtractDisabled}
              />
            ) : (
              <Text className="u-body u-body--xs font-bold">
                {quantity} uds.
              </Text>
            )}

            <Price
              {...(loading
                ? { loading: true }
                : { price: price ?? '', regularPrice: regularPrice })}
            />
          </div>
        </div>
      </ConditionalWrapper>

      {bundleItems && !loading && (
        <Disclosure as="div" className="w-full self-end">
          <Disclosure.Button className="u-body u-body--s ml-auto flex items-center gap-2 normal-case tracking-normal text-typo-primary">
            {({ open }) => (
              <>
                Ver contenido
                <ChevronDown
                  className={oneLine`text-secondary-500 transition ${open ? '-rotate-180' : ''} `}
                />
              </>
            )}
          </Disclosure.Button>

          <Disclosure.Panel className="pt-4">
            <table className="-mx-2 -my-2.5 w-[calc(100%+0.5rem*2)] border-separate border-spacing-x-2 border-spacing-y-2.5">
              <tbody className="u-body u-body--xs text-typo-secondary">
                {bundleItems.map((item, index) => (
                  <tr key={index}>
                    {/**
                     * Set a default width in a cell doesn't work but
                     * force it to adjust his width to the content
                     **/}
                    <td className="w-5">{item.quantity}x</td>
                    <td
                      className="line-clamp-1 w-full text-typo-primary"
                      title={item.name}
                    >
                      {item.name}
                    </td>
                    <td className="w-11 text-end">{toCurrency(item.price)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Disclosure.Panel>
        </Disclosure>
      )}
    </div>
  </CustomSkeletonTheme>
);
