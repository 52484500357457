import { NextLink } from '@/atoms';

import { toCurrency } from '@/lib/utils';

import type { FunctionComponent } from 'react';
import type { TypeCartLineFooterProps } from './types';

/**
 * CartLineFooter
 */
export const CartLineFooter: FunctionComponent<TypeCartLineFooterProps> = ({
  className = '',
  price,
  howMuchLeftForFreeShipping,
  shippingIcon,
  shippingLink,
}: TypeCartLineFooterProps) => (
  <div
    className={`flex flex-col gap-1 p-3 outline outline-1 outline-primary-100 md:px-6 ${className}`}
  >
    <div className="u-body u-body--s flex justify-between gap-4">
      <p className="inline-flex gap-1">
        Gastos de envío
        {shippingIcon}
      </p>

      <p className="font-bold uppercase">
        {price > 0 ? toCurrency(price) : 'Gratis'}
      </p>
    </div>

    {howMuchLeftForFreeShipping &&
      price > 0 &&
      howMuchLeftForFreeShipping > 0 && (
        <NextLink href={shippingLink || '#flexible-content'}>
          <a className="u-body u-body--xs text-secondary-500 underline">
            Faltan {toCurrency(howMuchLeftForFreeShipping)} para envío gratuito
          </a>
        </NextLink>
      )}
  </div>
);
