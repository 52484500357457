import Skeleton from 'react-loading-skeleton';

import { CustomSkeletonTheme } from '@/atoms';

import { getOrderStateColor } from '@/lib/utils';

import type { FunctionComponent } from 'react';
import type { TypeCartLineHeaderProps } from './types';

/**
 * CartLineHeader
 */
export const CartLineHeader: FunctionComponent<TypeCartLineHeaderProps> = ({
  className = '',
  title,
  description,
  loading = false,
  status,
  buttons,
}: TypeCartLineHeaderProps) => (
  <CustomSkeletonTheme>
    <div
      className={`flex w-full flex-col items-center gap-4 bg-white p-3 outline outline-1 outline-primary-100 sm:flex-row sm:px-6 ${className}`}
    >
      <div className="u-body w-full">
        <div className="u-body--s flex flex-row justify-between gap-6 sm:justify-start">
          <p className="font-bold uppercase">
            {loading ? <Skeleton containerClassName="w-52 block" /> : title}
          </p>

          {status && !loading && (
            <span className={getOrderStateColor(status)}>{status}</span>
          )}
        </div>

        {description && (
          <p className="u-body--xs text-typo-secondary">
            {loading ? <Skeleton /> : description}
          </p>
        )}
      </div>

      {buttons && (
        <div className="flex w-full flex-row gap-4 sm:w-auto sm:gap-6">
          {buttons}
        </div>
      )}
    </div>
  </CustomSkeletonTheme>
);
